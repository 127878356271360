<template>
 
  <router-view/>
</template>

<style>
body{
  background-color: #9195A3;
}
</style>
