<template>
  <section class="container-fluid">
    <div class="row mcas">
      <div
        class="col-md-6 col-lg-4 main"
        style="background: whitesmoke; padding: 0px"
      >
        <div class="row" style="padding: 0px" id="warea">
          <div
            class="col-12"
            style="
              padding: 0px;
              background: linear-gradient(to right, #fe9902, #e97802);
            "
          >
            <div class="row">
              <div
                class="col-12 xtl ram"
                style="
                  background: linear-gradient(to right, #fe9902, #e97802);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div><img src="/Image/logo.png" height="60" /></div>
                <div>
                  <a href="https://game.dnctimes.com/app.apk" download>
                    <img
                      src="/Image/headerimg2.png"
                      style="width: 30px; margin-right: 10px; margin-top: 3px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-56" style="padding: 0px">
            <div class="row tf-12 tfcdb tfw-7 1wtj0ep pbt-18" style="">
              <div class="mt-0" style="width: 100%">
                <img src="../assets/png/bonus3.jpeg" style="width: 100%" />
              </div>

              <div data-v-6c5117cd="" data-v-94f8731b="" class="gameList">
                <div
                  data-v-6c5117cd=""
                  role="tablist"
                  class="van-sidebar mySideBar"
                >
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item van-sidebar-item--select"
                    tabindex="0"
                    aria-selected="true"
                    id="gameType-lottery"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="whiteColor">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010214yxxq.png');
                          "
                        ></div>
                        Lottery
                      </div>
                      <!---->
                    </div>
                  </div>
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item"
                    tabindex="0"
                    aria-selected="false"
                    id="gameType-flash"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010207qmfa.png');
                          "
                        ></div>
                        Mini games
                      </div>
                      <!---->
                    </div>
                  </div>
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item"
                    tabindex="0"
                    aria-selected="false"
                    id="gameType-popular"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010159ynyu.png');
                          "
                        ></div>
                        Popular
                      </div>
                      <!---->
                    </div>
                  </div>
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item"
                    tabindex="0"
                    aria-selected="false"
                    id="gameType-slot"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010153317n.png');
                          "
                        ></div>
                        Slots
                      </div>
                      <!---->
                    </div>
                  </div>
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item"
                    tabindex="0"
                    aria-selected="false"
                    id="gameType-fish"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010147x2cr.png');
                          "
                        ></div>
                        Fishing
                      </div>
                      <!---->
                    </div>
                  </div>
                  <div
                    data-v-6c5117cd=""
                    role="tab"
                    class="van-sidebar-item"
                    tabindex="0"
                    aria-selected="false"
                    id="gameType-video"
                  >
                    <div class="van-badge__wrapper van-sidebar-item__text">
                      <div data-v-6c5117cd="" class="">
                        <div
                          data-v-6c5117cd=""
                          style="
                            background-image: url('https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010141v76m.png');
                          "
                        ></div>
                        Casino
                      </div>
                      <!---->
                    </div>
                  </div>
                </div>
                <div
                  data-v-906ddf09=""
                  data-v-6c5117cd=""
                  class="gameListGrid__container"
                  style=""
                >
                  <!----><!----><!---->
                  <div
                    data-v-785ec202=""
                    data-v-906ddf09=""
                    class="lottery_container"
                  >
                    <div
                      data-v-785ec202=""
                      onclick="window.location.href='/#win1/';"
                      class="lotterySlotItem"
                    >
                      <img
                        data-v-785ec202=""
                        class=""
                        src="https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_202307140102511fow.png"
                      /><span data-v-785ec202="">Win Go</span>
                      <h4 data-v-785ec202="">
                        <div data-v-785ec202="">Guess Number</div>
                        <div data-v-785ec202="">Green/Red/Purple to win</div>
                      </h4>
                    </div>
					
					<div
                      data-v-785ec202=""
                      @click="Geturl()"
                      class="lotterySlotItem"
                    >
                      <img
                        data-v-785ec202=""
                        class=""
                        src="https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010207qmfa.png"
                      /><span data-v-785ec202="">Avitor</span>
                      <h4 data-v-785ec202=""></h4>
                    </div>
					
                    <div
                      data-v-785ec202=""
                      onclick="window.location.href='#';"
                      class="lotterySlotItem"
                    >
                      <img
                        data-v-785ec202=""
                        class=""
                        src="https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_20230714010246lyuc.png"
                      /><span data-v-785ec202="">Trx Win</span>
                      <h4 data-v-785ec202="">
                        <div data-v-785ec202="">Guess Number</div>
                        <div data-v-785ec202="">Green/Red/Purple to win</div>
                      </h4>
                    </div>

                    <div
                      data-v-785ec202=""
                      onclick="window.location.href='#';"
                      class="lotterySlotItem"
                    >
                      <img
                        data-v-785ec202=""
                        class=""
                        src="https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_2023071401023322dy.png"
                      /><span data-v-785ec202="">5D</span>
                      <h4 data-v-785ec202="">
                        <div data-v-785ec202="">Guess Number</div>
                        <div data-v-785ec202="">Big/Small/Odd/Even</div>
                      </h4>
                    </div>

                    
                  </div>
                  <!----><button
                    onclick="window.location.href='#';"
                    data-v-906ddf09=""
                    class="look_all"
                  >
                    <img
                      data-v-906ddf09=""
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAIVBMVEUAAAD5Wlr3WFj6WVn6WFj5V1f6WVn5WVn5WVn4V1f5WVnpXZn/AAAACnRSTlMA72C/v1DP329wWe0twgAAAKNJREFUOMtjoA/gMFwFAkkgdrMUiLlMASyhtQoCGhgYmKDMFWCJLCivgIGBDcpcDpZYBQULGBi4YOwRLlEF5SQwMLBDmcvAErOgPGBYc0KZi8ASTI4g9kIjEFsFHB/CCgyDF7AaIpwbjHAuIvkEIJLPUrCEF5RnwMDAAmUuBktIQXkCDAyMUObCgY9BGkvg9Dn+sIpCJB9WbMnHFCn5BDDQBQAAbjlnhYlIhpEAAAAASUVORK5CYII="
                      alt=""
                    />
                    View All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="odrea"></div>

        <FooterComponent></FooterComponent>

        <div class="row" id="note"></div>
        <div class="row" id="anof">
          <div class="col-12 conod" @click="clink()" id="clink">
            <div class="ssmg banner flex fadein" id="smgid">
              <div class="xtc pt-2 pb-2">
                <img
                  src="../assets/includes/images/banner.png"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="dta_ref"></div>
      </div>
    </div>
  </section>
</template>

<script>
// import "../assets/index.css";

// import "../assets/promoView.css";
import "../assets/homeview.css";
import "../assets/modules-96f5a6e8.css";

import "../assets/mine.css";
import "../assets/wingo_indeex.css";
import "../assets/win.css";

import axios from "axios";
import FooterComponent from "./FooterComponent";

export default {
  components: {
    FooterComponent,
  },
  name: "HomeView",
  data() {
    return {
      count: 1,
      id: null,
      username: null,
      balance: null,
      Users: [],
    };
  },
  beforeCreate: function () {
    if (localStorage.getItem("username") == null) {
      this.$router.push({name: "login"});
    }
  },
  created: function () {},

  beforeUnmount: function () {
    clearInterval(this.repeat);
  },
  mounted: function () {
    this.check();
    this.getUserdetails();
  },
  methods: {
    check() {
      if (localStorage.getItem("note") == "true") {
        document.getElementById("clink").style.display = "none";
      } else {
        document.getElementById("clink").style.display = "none";
        console.log(localStorage.getItem("note"));
      }
    },
    clink() {
      document.getElementById("clink").style.display = "none";
      localStorage.setItem("note", true);
    },
    reload() {
      document.getElementById("lhsd").classList.add("wals");
      this.getUserdetails();
      setTimeout(function e() {
        document.getElementById("lhsd").classList.remove("wals");
      }, 1000);
    },
    getUserdetails() {
      this.username = localStorage.getItem("username");
      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/me.php?action=getuserinfo&user=" +
            this.username
        )
        .then((res) => {
          this.Users = res.data.user_Data;
          this.id = this.Users[0].id;
          this.balance = this.Users[0].balance;
          this.url =
            "https://avitor.dnctimes.com/loginbyid?username=" +
            this.username +
            "&name=test&email=test@gmail.com";
          console.log(this.Users);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Geturl() {
      window.location.href = this.url;
    },
  },
};
</script>
