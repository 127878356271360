
<template> 
 <div
      data-v-6ab3f23e=""
      class="tabbar__container"
      style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif"
    >
      <div data-v-6ab3f23e="" class="tabbar__container-item" onclick="window.location.href='#/'">
        <svg data-v-6ab3f23e="" class="svg-icon icon-home">
          <use xlink:href="#icon-home"></use></svg
        ><!--v-if--><span data-v-6ab3f23e="">Home</span>
      </div>
      <div data-v-6ab3f23e="" class="tabbar__container-item" onclick="window.location.href='#/activity'" >
        <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
          <use xlink:href="#icon-activity"></use></svg
        ><!--v-if--><span data-v-6ab3f23e="">Activity</span>
      </div>
      <div data-v-6ab3f23e="" class="tabbar__container-item"  onclick="window.location.href='#/promotion'" >
        <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
          <use xlink:href="#icon-promotion"></use>
        </svg>
        <div data-v-6ab3f23e="" class="promotionBg"></div>
        <span data-v-6ab3f23e="">Promotion</span>
      </div>
      <div data-v-6ab3f23e="" class="tabbar__container-item" onclick="window.location.href='#/wallet'">
        <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
          <use xlink:href="#icon-wallet"></use></svg
        ><!--v-if--><span data-v-6ab3f23e="">Wallet</span>
      </div>
      <div data-v-6ab3f23e="" class="tabbar__container-item active"  onclick="window.location.href='#/mine'">
        <svg data-v-6ab3f23e="" class="svg-icon icon-main">
          <use xlink:href="#icon-main"></use></svg
        ><!--v-if--><span data-v-6ab3f23e="">Account</span>
      </div>
    </div>
	
</template>